import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import Layout from '../../components/Layout';
import SEO from '../../components/Seo';
import PageHeader from '../../components/PageHeader';
import SectionLeftRight from '../../components/SectionLeftRight';
import FeaturesGrid from '../../components/FeaturesGrid';
import Section from '../../components/Section';
import Grid from '../../components/Styles/Grid';
import Typography from '../../components/Styles/Typography';
import CardFeature from '../../components/CardFeature';

import MoneyBill1Icon from '../../components/Icons/Fa/Thin/MoneyBill1Icon';
import ListCheckIcon from '../../components/Icons/Fa/Thin/ListCheckIcon';
import PenToSquareIcon from '../../components/Icons/Fa/Thin/PenToSquareIcon';

import ClockRotateLeftIcon from '../../components/Icons/Fa/Thin/ClockRotateLeftIcon';
import AnglesUpIcon from '../../components/Icons/Fa/Thin/AnglesUpIcon';
import ArrowsRotateIcon from '../../components/Icons/Fa/Thin/ArrowsRotateIcon';
import GaugeIcon from '../../components/Icons/Fa/Thin/GaugeIcon';
import FingerprintIcon from '../../components/Icons/Fa/Thin/FingerprintIcon';
import CloneIcon from '../../components/Icons/Fa/Thin/CloneIcon';
import FileChartPieIcon from '../../components/Icons/Fa/Thin/FileChartPieIcon';
import LinkIcon from '../../components/Icons/Fa/Thin/LinkIcon';
import ShieldVirusIcon from '../../components/Icons/Fa/Thin/ShieldVirusIcon';

const WebDesignPage = (props) => {
  const { data } = props;
  return (
    <Layout>
      <SEO title="Website Maintenance &amp; Tech Support in Boston" />
      <PageHeader
        heading="Support <span class='highlight'>&amp;</span> Maintenance "
        subHeading="ALTdev is one of the best website maintenance services company, helping businesses across the globe improve the performance and security of their websites. Whether you’re looking for a one time fix or an ongoing engament, ALTdev provides the experience and expertise your company demands. Start focusing on your business while we keep your website secure, healthy and up-to-date."
        buttonText="Request a Quote"
        buttonLink="/contact/"
      />

      <SectionLightBlue topDivider={true} bottomDivider={true}>
        <Typography as="h2" textAlign="center">
          On Demand Support
        </Typography>
        <Grid
          gridTemplateColumns={['1fr', '1fr 1fr', '1fr 1fr 1fr']}
          gridGap={4}
        >
          <CardFeature
            heading="Flat-Rate Changes"
            text="Not quite ready for one of our support plans, but still need help? With our transparent pricing, you know exactly what you are investing in upfront."
            icon={<MoneyBill1Icon />}
          />
          <CardFeature
            heading="Project-based"
            text="Need help with a project? You tell us what you have in mind, and we’ll take care of the rest. Let’s get started."
            icon={<ListCheckIcon />}
          />
          <CardFeature
            heading="Content Additions"
            text="You need to be adding content regularly to get the most out of your online presence. Our team will take care of it for you so you can get back to running your business."
            icon={<PenToSquareIcon />}
          />
        </Grid>
      </SectionLightBlue>

      <SectionStyles>
        <Typography as="h2">
          Website Maintenance Built for Your Business
        </Typography>
        <Typography>
          Website maintenance needs to be a top priority for businesses today.
          Customers come to your website to explore your products, browse your
          services. That first impression can determine if you make that next
          sale or not. We provide your business with a comprehensive website
          maintenance plan build just for you, maximizing your site’s
          performance.
          <br />
          <a href="/contact/">Contact us</a> today so you can tell us about your
          business and it's needs.
        </Typography>
      </SectionStyles>

      <SectionLightBlue topDivider={true}>
        <Typography as="h2" textAlign="center">
          Wordpress Maintenance &amp; Support
        </Typography>
        <Grid
          gridTemplateColumns={['1fr', '1fr 1fr', '1fr 1fr 1fr']}
          gridGap={4}
        >
          <CardFeature
            heading="Backups"
            text="Reliable, off-site storage, of both the server and website. Run weekly, daily and hourly."
            icon={<ClockRotateLeftIcon />}
          />
          <CardFeature
            heading="Uptime Monitor"
            text="We monitor your site 24/7 for any issues, so we can start to get it back online before anyone else notices."
            icon={<AnglesUpIcon />}
          />
          <CardFeature
            heading="Updates"
            text="Regular updates are required to ensure the performance and security of your website."
            icon={<ArrowsRotateIcon />}
          />
          <CardFeature
            heading="Security Check"
            text="Make sure your site are clean and free of any Malware. If any files do become infected we can spot them in time."
            icon={<FingerprintIcon />}
          />
          <CardFeature
            heading="Performance Check"
            text="Get insights into how your website is performing while catching performance issues early."
            icon={<GaugeIcon />}
          />
          <CardFeature
            heading="Monthly Reports"
            text="Stay informed at all times with monthly Reports."
            icon={<FileChartPieIcon />}
          />
          <CardFeature
            heading="Hacked Website Repair"
            text="Make sure your site are clean and free of any Malware. If any files do become infected we can spot them in time."
            icon={<ShieldVirusIcon />}
          />
          <CardFeature
            heading="Migrate / Clone"
            text="Clone your website to a staging area, migrate to a new host, or clone to an existing site."
            icon={<CloneIcon />}
          />
          <CardFeature
            heading="Link Monitoring"
            text="We perform regular scans of your entire WordPress site for broken links."
            icon={<LinkIcon />}
          />
        </Grid>
      </SectionLightBlue>

      <SectionGradient
        image={data.rocketDeveloper.childImageSharp.gatsbyImageData}
        heading="Hosting you can trust!"
        content="Stop sharing resources with 20+ other websites hosted on the same server. Our Fully Managed Hosting Service is a state-of-the art environment, that gives your site the best performance and security. With 24/7 monitoring and support and a self healing infustructure to limit any possible down time."
        buttonText="Learn More"
        buttonLink="/services/managed-web-hosting/"
        flip
      />
    </Layout>
  );
};

const SectionGradient = styled(SectionLeftRight)`
  color: white;
  background: var(--linearGradientMixed);
  & button {
    background: white;
    color: var(--textColor);
  }
`;

const FeaturesGridStyled = styled(FeaturesGrid)`
  background: var(--lightBlue);
`;

const SectionStyles = styled(Section)`
  @media (min-width: 960px) {
    .inner {
      padding-right: 25%;
    }
  }
`;

const SectionLightBlue = styled(Section)`
  background: var(--lightBlue);
  svg {
    fill: var(--secondary);
  }
`;

export default WebDesignPage;

export const query = graphql`
  query {
    rocketDeveloper: file(relativePath: { eq: "rocket-developer.png" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
  }
`;
