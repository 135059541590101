
		import React from 'react';

		function AnglesUpIcon() {
			return (
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0, 0 384, 512">
					<path d="M45.53 221.8l146.5-138.7l146.5 138.7c3.156 3.031 8.218 2.938 11.31-.25c3.062-3.188 2.937-8.266-.25-11.31l-151.1-144C195.1 64.73 194 64 192 64S188 64.73 186.5 66.22L34.47 210.2c-3.187 3.047-3.312 8.125-.25 11.31C37.25 224.7 42.31 224.8 45.53 221.8zM197.5 258.2C195.1 256.7 194 256 192 256S188 256.7 186.5 258.2l-152 144c-3.187 3.047-3.312 8.125-.25 11.31c3.031 3.188 8.093 3.281 11.31 .25l146.5-138.7l146.5 138.7c3.156 3.031 8.218 2.938 11.31-.25c3.062-3.188 2.937-8.266-.25-11.31L197.5 258.2z" />
				</svg>
			);
		}

		export default AnglesUpIcon;
	