
		import React from 'react';

		function MoneyBill1Icon() {
			return (
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0, 0 576, 512">
					<path d="M528 64h-480C21.49 64 0 85.49 0 112v288C0 426.5 21.49 448 48 448h480c26.51 0 48-21.49 48-48v-288C576 85.49 554.5 64 528 64zM560 400c0 17.64-14.36 32-32 32h-480c-17.64 0-32-14.36-32-32v-288c0-17.64 14.36-32 32-32h480c17.64 0 32 14.36 32 32V400zM528 168c-30.88 0-56-25.12-56-56c0-4.422-3.578-8-8-8s-8 3.578-8 8c0 39.7 32.3 72 72 72c4.422 0 8-3.578 8-8S532.4 168 528 168zM48 328c-4.422 0-8 3.578-8 8s3.578 8 8 8c30.88 0 56 25.12 56 56c0 4.422 3.578 8 8 8s8-3.578 8-8C120 360.3 87.7 328 48 328zM112 104c-4.422 0-8 3.578-8 8c0 30.88-25.12 56-56 56c-4.422 0-8 3.578-8 8S43.58 184 48 184c39.7 0 72-32.3 72-72C120 107.6 116.4 104 112 104zM528 328c-39.7 0-72 32.3-72 72c0 4.422 3.578 8 8 8s8-3.578 8-8c0-30.88 25.12-56 56-56c4.422 0 8-3.578 8-8S532.4 328 528 328zM312 312h-16V192c0-4.422-3.578-8-8-8H272C267.6 184 264 187.6 264 192S267.6 200 272 200h8v112h-16C259.6 312 256 315.6 256 320s3.578 8 8 8h48c4.422 0 8-3.578 8-8S316.4 312 312 312zM288 128C230.6 128 184 185.3 184 256s46.56 128 104 128s104-57.31 104-128S345.4 128 288 128zM288 368c-48.52 0-88-50.24-88-112S239.5 144 288 144s88 50.24 88 112S336.5 368 288 368z" />
				</svg>
			);
		}

		export default MoneyBill1Icon;
	