import React, { Component } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import Image from './Image';
import Inner from './Styles/Inner';
import Button from './Buttons/Button';
import Section from './Section';
import Text from './Styles/Text';
import Heading from './Styles/Heading';

const SectionLeftRight = (props) => {
  const {
    flip,
    subHeading,
    heading,
    content,
    buttonText,
    buttonLink,
    image,
    imageAltText,
    className,
  } = props;
  return (
    <Section className={className}>
      <Wrapper>
        <HalfOne flip={flip} className="half">
          {subHeading && <SubHeading>{subHeading}</SubHeading>}
          <Heading>{heading}</Heading>
          <Text dangerouslySetInnerHTML={{ __html: `${content}` }} />
          {buttonText && (
            <Link to={buttonLink}>
              <Button>{buttonText}</Button>
            </Link>
          )}
        </HalfOne>
        <HalfTwo flip={flip} className="half">
          <GatsbyImage image={image} altText={imageAltText} />
        </HalfTwo>
      </Wrapper>
    </Section>
  );
};

const Wrapper = styled.div`
  display: grid;
  grid-column-gap: 10%;
  grid-template-columns: 45% 45%;
  align-items: center;
  justify-items: start;
  .half {
    width: 100%;
  }
  @media (max-width: 960px) {
    grid-column-gap: 0;
    grid-template-columns: repeat(auto-fit, minmax(100%, auto));
  }
`;

const SubHeading = styled.span`
  text-transform: uppercase;
  font-size: 0.85em;
  margin-bottom: 16px;
  display: block;
  color: var(--secondary);
`;

const HalfOne = styled.div`
  order: ${(props) => (!props.flip ? 2 : 1)};
`;

const HalfTwo = styled.div`
  order: ${(props) => (!props.flip ? 1 : 2)};
  @media (max-width: 960px) {
    display: none;
  }
`;
SectionLeftRight.propTypes = {
  flip: PropTypes.bool,
  subHeading: PropTypes.string,
  heading: PropTypes.string,
  content: PropTypes.string,
  buttonText: PropTypes.string,
  buttonLink: PropTypes.string,
  image: PropTypes.object,
  imageAltText: PropTypes.string,
  className: PropTypes.string,
};

export default SectionLeftRight;
