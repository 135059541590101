
		import React from 'react';

		function GaugeIcon() {
			return (
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0, 0 576, 512">
					<path d="M96 336c-8.836 0-16 7.162-16 16c0 8.836 7.164 16 16 16s16-7.164 16-16C112 343.2 104.8 336 96 336zM288 144c8.836 0 16-7.164 16-16c0-8.838-7.164-16-16-16S272 119.2 272 128C272 136.8 279.2 144 288 144zM144 176C135.2 176 128 183.2 128 192c0 8.836 7.164 16 16 16S160 200.8 160 192C160 183.2 152.8 176 144 176zM480 336c-8.836 0-16 7.162-16 16c0 8.836 7.164 16 16 16s16-7.164 16-16C496 343.2 488.8 336 480 336zM433.5 171.3l-113.3 155C310.2 322.3 299.4 320 288 320c-48.53 0-88 39.47-88 87.1C200 412.4 203.6 416 208 416s8-3.578 8-8c0-39.7 32.31-72 72-72s72 32.3 72 72c0 4.422 3.594 8 8 8s8-3.578 8-8c0-31.35-16.59-58.72-41.33-74.31l111.8-152.1c2.594-3.562 1.812-8.578-1.75-11.17C441.2 166.1 436.2 167.7 433.5 171.3zM288 32c-158.8 0-288 129.2-288 288c0 55.09 16.25 109.2 46.94 156.4C48.44 478.6 50.94 480 53.66 480h468.7c2.719 0 5.219-1.375 6.719-3.641C559.8 429.2 576 375.1 576 320C576 161.2 446.8 32 288 32zM517.1 464H58.03C30.53 420.3 16 370.6 16 320c0-149.1 122-272 272-272s272 122 272 272C560 370.6 545.5 420.3 517.1 464z" />
				</svg>
			);
		}

		export default GaugeIcon;
	