
		import React from 'react';

		function PenToSquareIcon() {
			return (
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0, 0 512, 512">
					<path d="M439.1 279c-4.406 0-7.1 3.578-7.1 8v152.1c0 30.88-25.12 56-55.1 56H71.1c-30.87 0-55.1-25.13-55.1-56V135.1c0-30.88 25.12-56 55.1-56h152.2c4.406 0 7.1-3.578 7.1-8c0-4.422-3.594-8-7.1-8L71.1 63.99C32.31 63.99 0 96.29 0 135.1v304C0 479.7 32.31 512 71.1 512h303.1c39.69 0 71.1-32.3 71.1-72l-.0068-152.1C447.1 282.6 444.4 279 439.1 279zM501.5 55.62l-45.07-45.07C449.4 3.516 440.1 0 430.9 0s-18.44 3.516-25.48 10.55L151.3 264.7C149.6 266.4 148.5 268.5 147.1 270.8l-19.75 98.79c-1.516 7.584 4.378 14.36 11.72 14.36c.7851 0 1.586-.0764 2.399-.2385l98.78-19.76c2.324-.4648 4.457-1.606 6.133-3.279l254.2-254.2C515.5 92.49 515.5 69.68 501.5 55.62zM236.8 348.5l-91.73 18.42L163.4 275.2l194.2-194.2l73.38 73.38L236.8 348.5zM490.1 95.24l-47.8 47.79l-73.38-73.39l47.79-47.79c3.781-3.779 8.808-5.86 14.16-5.86s10.38 2.08 14.16 5.86l45.07 45.07C497.9 74.74 497.9 87.44 490.1 95.24z" />
				</svg>
			);
		}

		export default PenToSquareIcon;
	