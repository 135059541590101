
		import React from 'react';

		function ClockRotateLeftIcon() {
			return (
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0, 0 512, 512">
					<path d="M256 24c-95.43 0-181 60.14-215.4 147.9L20.53 71.69C19.66 67.34 15.28 64.63 11.13 65.41C6.781 66.28 3.969 70.5 4.844 74.81l25.11 125.5c.7656 3.812 4.094 6.438 7.828 6.438c.5313 0 1.062-.0625 1.578-.1562L164.9 181.5c4.344-.875 7.141-5.094 6.281-9.406C170.3 167.8 165.8 165 161.8 165.8l-110.3 22.05C80.57 100.6 163.4 40 256 40c119.1 0 216 96.91 216 216s-96.89 216-216 216c-44.44 0-87.13-13.38-123.4-38.72c-3.562-2.5-8.594-1.688-11.14 2c-2.531 3.625-1.641 8.594 1.984 11.12C162.4 473.6 208.3 488 256 488c127.9 0 232-104.1 232-232S383.9 24 256 24zM256 128C251.6 128 248 131.6 248 136V256c0 2.125 .8438 4.156 2.344 5.656l88 88C339.9 351.2 341.1 352 344 352s4.094-.7813 5.656-2.344c3.125-3.125 3.125-8.188 0-11.31L264 252.7V136C264 131.6 260.4 128 256 128z" />
				</svg>
			);
		}

		export default ClockRotateLeftIcon;
	