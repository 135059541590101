import React from 'react';
import styled from 'styled-components';
import Heading from './Styles/Heading';
import Text from './Styles/Text';
import Section from './Section';
import Grid from './Styles/Grid';
import CardFeature from './CardFeature';

function FeaturesGrid(props) {
  return (
    <Section className={props.className}>
      <Heading textAlign="center" marginBottom="2em">
        {props.heading}
      </Heading>
      <Grid gridTemplateColumns="1fr 1fr 1fr">
        {props.data.map((data) => (
          <CardFeature
            heading={data.heading}
            text={data.text}
            icon={data.icon}
          />
        ))}
      </Grid>
    </Section>
  );
}

export default FeaturesGrid;
