
		import React from 'react';

		function ListCheckIcon() {
			return (
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0, 0 512, 512">
					<path d="M48 367.1c-26.51 0-48 21.49-48 48S21.5 464 48 464s48-21.49 48-48S74.51 367.1 48 367.1zM48.06 448C30.42 448 16 433.6 16 416s14.42-32 32.06-32S80 398.4 80 416S65.71 448 48.06 448zM58.78 134.1C60.31 135.4 62.16 136 64 136c2.062 0 4.094-.7813 5.656-2.344l88-88c3.125-3.125 3.125-8.188 0-11.31s-8.188-3.125-11.31 0L63.59 117.1L13.22 73.92C9.844 71.08 4.781 71.44 1.938 74.8c-2.875 3.344-2.5 8.406 .8438 11.28L58.78 134.1zM200 104h304C508.4 104 512 100.4 512 96s-3.594-8-8-8h-304C195.6 88 192 91.59 192 96S195.6 104 200 104zM504 248h-304C195.6 248 192 251.6 192 256s3.594 8 8 8h304C508.4 264 512 260.4 512 256S508.4 248 504 248zM504 408H168C163.6 408 160 411.6 160 416s3.594 8 8 8h336c4.406 0 8-3.594 8-8S508.4 408 504 408zM157.7 194.3c-3.125-3.125-8.188-3.125-11.31 0L63.59 277.1L13.22 233.9C9.844 231 4.781 231.5 1.938 234.8c-2.875 3.344-2.5 8.406 .8438 11.28l56 48C60.31 295.4 62.16 296 64 296c2.062 0 4.094-.7813 5.656-2.344l88-88C160.8 202.5 160.8 197.5 157.7 194.3z" />
				</svg>
			);
		}

		export default ListCheckIcon;
	