
		import React from 'react';

		function LinkIcon() {
			return (
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0, 0 640, 512">
					<path d="M602.6 37.44c-49.92-49.92-131.2-49.92-181.1 0L346.3 112.6c-3.127 3.125-3.127 8.189 0 11.31c3.125 3.127 8.189 3.127 11.31 0l75.16-75.17c43.7-43.67 114.8-43.67 158.4 0c43.66 43.68 43.66 114.8 0 158.4l-128 128c-43.7 43.67-114.8 43.67-158.4 0C268.1 298.5 261.6 240.1 289.2 196.3c2.359-3.736 1.25-8.674-2.502-11.04C283.1 182.9 278.1 183.1 275.7 187.7c-32.15 50.87-24.83 116.2 17.79 158.8c24.96 24.96 57.75 37.43 90.54 37.43c32.79 0 65.58-12.47 90.53-37.43l128-128C652.5 168.6 652.5 87.36 602.6 37.44zM282.3 388.1l-75.16 75.17c-43.7 43.67-114.8 43.67-158.4 0c-43.66-43.68-43.66-114.8 0-158.4l128-128c43.7-43.67 114.8-43.67 158.4 0c36.7 36.71 43.24 95.15 15.56 138.1c-2.359 3.734-1.25 8.674 2.502 11.03c3.688 2.328 8.672 1.297 11.03-2.5c32.15-50.87 24.83-116.2-17.79-158.8c-49.92-49.92-131.2-49.92-181.1 0L37.43 293.5c-49.9 49.92-49.9 131.2 0 181.1C62.38 499.5 95.17 512 127.1 512c32.79 0 65.58-12.47 90.53-37.43l75.16-75.17c3.127-3.127 3.127-8.189 0-11.32C290.5 384.1 285.5 384.1 282.3 388.1z" />
				</svg>
			);
		}

		export default LinkIcon;
	