import React from 'react';
import styled from 'styled-components';
import Heading from './Styles/Heading';
import Text from './Styles/Text';
import Card from './Styles/Card';

const CardFeature = ({ icon, heading, text, link, linkText, boxShadow }) => (
  <CardStyle link={link}>
    {icon && <IconWrap>{icon}</IconWrap>}
    <HeadingStyled as="h3">{heading}</HeadingStyled>
    <Text dangerouslySetInnerHTML={{ __html: `${text}` }} />
  </CardStyle>
);

const HeadingStyled = styled(Heading)`
  font-size: 1.4em;
`;

const CardStyle = styled(Card)`
  background: transparent;
  text-align: center;
`;
const IconWrap = styled.span`
  display: inline-block;
  width: 55px;
  height: 55px;
  margin-bottom: 1em;
`;

export default CardFeature;
